import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import InfoLayout from "../components/layout/info"

const Information = ({ data }) => {
  const getStatusTag = (status) => {
    if (status === "done") {
      return (<span className="tag is-success m-3 p-3">完了</span>)
    }
    else if (status === "processing") {
      return (<span className="tag is-warning m-3 p-3">実施中</span>)
    }
    else {
      return (<span></span>)
    }
  }

  return (<InfoLayout>
    {data.allMdx.edges.map(({ node }) => (
      <article>
        <h2 className="title is-size-3">{node.frontmatter.title}{getStatusTag(node.frontmatter.status)}</h2>
        <MDXRenderer className="content">{node.body}</MDXRenderer>
      </article>
  ))}
  </InfoLayout>);
}

export const query = graphql`
query InformationQuery {
  allMdx(
    filter: {frontmatter: {category: {eq: "info"}}}
    sort: {fields: [frontmatter___date], order: DESC}
    limit: 1
  ) {
    edges {
      node {
        id
        body
        frontmatter {
          title
          date(formatString: "YYYY年MM月DD日")
          keywords
          slug
          category
          status
        }
      }
    }
  }
  file(relativePath: {eq: "110th-anniversary-1.jpg"}) {
    id
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Information;