import React from "react"
import InfoMenuItem from "./menu/info"

const InfoMenu = () => {
  return (
    <aside class="menu mx-4">
      <InfoMenuItem />
    </aside>
    );
}



export default InfoMenu;