import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const InfoMenuItem = () => {
    const infoData = useStaticQuery(graphql`
        query InfoItemsQuery {
            allMdx(
                sort: {order: DESC, fields: [frontmatter___date]}
                filter: {frontmatter: {category: {eq: "info"}}}
                limit: 1000
            ) {
                edges {
                node {
                    frontmatter {
                        title
                        slug
                        date(formatString: "YYYY年MM月DD日")
                        year: date(formatString: "YYYY")
                        month: date(formatString: "MM")
                    }
                }
                }
            }
            }
    `);
    return (
        <ul className="menu-list">
            {infoData.allMdx.edges.map(({ node }) => (
            <li>
                <Link to={`${node.frontmatter.slug}`}>
                    {node.frontmatter.date}
                </Link>
            </li>
        ))}
        </ul>
    );
}

export default InfoMenuItem;